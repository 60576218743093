import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import emptyImg from '../../assets/images/shared/empty-image.svg';
import '../../markup/bootstrap-grid.rtl.min.css';
import associationStore from '../../markup/images/associationStore.svg';
import associationStoreIcon from '../../markup/images/associationStoreIcon.svg';
import Call from '../../markup/images/call.svg';
import iconeClosePopup from '../../markup/images/close-popup.png';
import directDonation from '../../markup/images/directDonation.svg';
import directDonationIcon from '../../markup/images/directDonationIcon.svg';
import Idea from '../../markup/images/idea.png';
import Map from '../../markup/images/map-contact.svg';
import Email from '../../markup/images/message-contact.svg';
import WhatsappLogo from '../../markup/images/whatsapp-logo.png';
import xLogo from '../../markup/images/x-logo-border.png';
import userPic from '../../markup/images/userPic.png';
import { resetUserData } from '../../redux/actions/userData';
import { getBanksData, getLandingPageData, getMembersData } from '../../services/axios/api';
import authManager from '../../services/axios/authManager';
import Footer from '../../shared/components/Footer/Footer';
import { MEMBERS_POSITIONS } from '../Admin/CMS/UpdateMembers/membersPositions';
import Header from './Components/Header';
import './index.scss';

const LandingPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.userData?.fetchingReducer?.data);
  const [activeNav, setActiveNav] = useState(false);
  const [homePageData, setHomePageData] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [banksData, setBanksData] = useState([]);
  const [showTab, setShowTab] = useState(1);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const handelTab = (e) => {
    setShowTab(e);
  };

  const aboutContainerStyle = classNames('nav-container', {
    'nav-container--active': activeNav,
  });

  const token = authManager.getAccessToken();
  useEffect(() => {
    if (!token) dispatch(resetUserData());
    fetchHomeData();
    // eslint-disable-next-line
  }, []);

  const fetchHomeData = async () => {
    const data = await getLandingPageData();
    const members = await getMembersData();
    const banks = await getBanksData();
    setHomePageData(data);
    getCards(members, data);
    setBanksData(banks);
  };

  const setText = (name) => {
    try {
      return homePageData?.find((section) => section?.identifier === name)?.title;
    } catch (error) {
      console.log('--error', error);
    }
  };

  const features = setText('main_section_4_2');
  const featuresArr = features?.split('.');
  const featuresArrClean = featuresArr?.slice(0, -1);

  const hrefLink = useMemo(() => {
    try {
      return homePageData && homePageData?.find((obj) => obj.identifier === 'main_section_6_3')?.url_link;
    } catch (error) {
      console.log('--error', error);
    }
  }, [homePageData]);

  const getCards = (members, titles) => {
    try {
      const firstCard = members?.filter((member) => member.data_type === 0);
      const secondCard = members?.filter((member) => member.data_type === 1);
      const thirdCard = members?.filter((member) => member.data_type === 2);
      const fourthCard = members?.filter((member) => member.data_type === 3);
      const fifthCard = members?.filter((member) => member.data_type === 4);
      const sixthCard = members?.filter((member) => member.data_type === 5);
      const seventhCard = members?.filter((member) => member.data_type === 6);
      const firstCardTitle = titles?.find((section) => section.identifier === 'about_section_4')?.title;
      const secondCardTitle = titles?.find((section) => section.identifier === 'about_section_5')?.title;
      const thirdCardTitle = titles?.find((section) => section.identifier === 'about_section_6')?.title;
      const fourthCardTitle = titles?.find((section) => section.identifier === 'about_section_7')?.title;
      const fifthCardTitle = titles?.find((section) => section.identifier === 'about_section_8')?.title;
      const sixthCardTitle = titles?.find((section) => section.identifier === 'about_section_9')?.title;
      const seventhCardTitle = titles?.find((section) => section.identifier === 'about_section_12_5')?.title;
      const cards = [
        { members: firstCard, title: firstCardTitle },
        { members: secondCard, title: secondCardTitle },
        { members: thirdCard, title: thirdCardTitle },
        { members: fourthCard, title: fourthCardTitle },
        { members: fifthCard, title: fifthCardTitle },
        { members: sixthCard, title: sixthCardTitle },
        { members: seventhCard, title: seventhCardTitle },
      ];
      setMembersData(cards);
    } catch (error) {
      console.log('--error', error);
    }
  };

  // const { cards, firstCardTitle, secondCardTitle, thirdCardTitle, fourthCardTitle, fifthCardTitle, sixthCardTitle } = getCards() ?? {
  //   cards: [],
  // };
  const findImage = (fieldName) => {
    try {
      return (homePageData && homePageData?.find((obj) => obj.identifier === fieldName)?.image) || emptyImg;
    } catch (error) {
      console.log('--error', error);
    }
  };

  const generateSubPoints = (subPoints) => {
    return subPoints.map((subPoint, index) => (
      <a
        key={index}
        href={subPoint.link || '#'}
        className={`gov-subpoint dot ${subPoint.link ? 'gov-subtitlelink' : ''}`}
        onClick={() => subPoint.onClick}
      >
        {subPoint.text}
      </a>
    ));
  };

  const ContentComponent = ({ mainPoint, subPointDiv, subPoints }) => {
    return (
      <div>
        {mainPoint && mainPoint.length > 0 && (
          <h2 className='gov-subtitle' style={{ marginBottom: 0 }}>
            {mainPoint}
          </h2>
        )}
        {subPointDiv && subPointDiv.length > 0 && (
          <div id='governance' style={{ marginBottom: 20, marginTop: 15 }}>
            <a className='gov-subpoint-div'>{subPointDiv}</a>
          </div>
        )}
        {subPoints && subPoints.length > 0 && (
          <div id='governance' className='gov-point-container'>
            {generateSubPoints(subPoints)}
          </div>
        )}
      </div>
    );
  };

  let url = 'https://drive.google.com/file/d/';

  const ars_subPoints = [
    { text: 'اللائحة الأساسية للجمعية', link: url + '12Rmprf43Kl9GULDNz4Nrzl01J2VfX9TE/view?usp=sharing' },
    { text: 'شهادة تسجيل الجمعية', link: url + url + '19hDKafyRUAsRsU_EDk3CbplkkDKkFgpc/view?usp=sharing' },
    { text: 'شهادة ترخيص المركز الوطني', link: url + '1Ysjb50wd5F02dMCDFUnGYPkyMa5qM27s/view?usp=sharing' },
    { text: 'آلية التأكد من استحقاق المستفيد للخدمة', link: url + '1Ysjb50wd5F02dMCDFUnGYPkyMa5qM27s/view?usp=sharing' },
    { text: 'آلية قبول أعضاء الجمعية العمومية ', link: url + '1zMeKgfViJ59i7LH8XKUhiWOSHJTDLH4-/view?usp=drive_link' },
    { text: 'تقييم المخاطر المتأصلة والكامنة لجمعية تعلم ', link: url + '1zMB4JXDU7AFvBpG5XNJBO97Jn8CufNvD/view?usp=drive_link' },
    {
      text: 'الدليل الاجرائي لتجنب تنبيه العميل أو المتبرع المشتبه به ',
      link: url + '1yqwidWDDH8edM4WUpyV-ojBgusqbpL2A/view?usp=drive_link',
    },
    { text: 'الدليل التعريفي لمجلس الإدارة', link: url + '1ytpGrzFvNXbjMZ_so-AsNwhyVOkjpyUT/view?usp=drive_link' },
    {
      text: 'دليل مؤشرات وإجراءات عمليات غسيل الأموال وتـــمــويل الإرهـاب',
      link: url + '1zOmW1wv55MKGoofZtat_SZbc77boch4b/view?usp=drive_link',
    },
    { text: 'سياسة إدارة المتطوعين', link: url + '13K33Gc_jLaKttlCP6RrSdJKY219aqecn/view?usp=drive_link' },
    { text: 'سياسة إدارة المخاطر', link: url + '13IDS11B0Fgl8OQOw3-bRmTiNSfU0dhRT/view?usp=drive_link' },
    {
      text: 'سياسة الإبلاغ عن المخالفات وحماية مقدمي البلاغات لجمعية تعلم ',
      link: url + '13CsgwLIna8_-V0m1vQbk3J9m5Sc6mLQ0/view?usp=sharing',
    },
    { text: 'سياسة الاحتفاظ بالوثائق وإتلافها', link: url + '13ABYlpAVuDg9U7uwE0GKi-5iJy7jEjGY/view?usp=drive_link' },
    { text: 'سياسة الاستثمار', link: url + '137qdUWTEk1CpsdFgPl6zL7CdQHd0Ut8L/view?usp=drive_link' },
    {
      text: 'سياسة الاشتباه بعمليات غسل الأموال وجرائم تمويل الإرهاب',
      link: url + '136WmebUdFmnxd8uEwBkLXlfWUANrlPt_/view?usp=drive_link',
    },
    {
      text: 'سياسة الصرف للبرامج والأنشطة والمصروفات الإدارية والعمومية ',
      link: url + '131jocSStIV2hZtofLWCu0aaYW7C3hSMK/view?usp=sharing',
    },
    {
      text: 'سياسة الوقاية من عمليات غسل الأموال وجرائم تمويل الإرهاب',
      link: url + '12wsWBYP3w6YzSwedG1AEVebG-5bYcOvs/view?usp=drive_link',
    },
    { text: 'سياسة تعارض المصالح', link: url + '12s6qVr4JtdmUObelr-9jDOOHik2wD80Z/view?usp=drive_link' },
    { text: 'سياسة تنظيم العلاقة مع المستفيدين', link: url + '12n6hUvJJvqz69jJZF4ssYK2aj7FeYil7/view?usp=drive_link' },
    { text: 'سياسة جمع التبرعات', link: url + '12leQ1-tWtNnFV5iIir9Wyd_EwXUuwpcT/view?usp=drive_link' },
    { text: 'سياسة خصوصية البيانات', link: url + '12dnoUeblkkfBR9qI83jRvgYr4fwf1KYc/view?usp=drive_link' },
    {
      text: 'قائمة مخاطر تمويل الارهاب وغسل الأموال تتطابق مع عمل الجمعية',
      link: url + '12_hMGQceTD4N0hT_DZBT_iTXuaBy7PLR/view?usp=drive_link',
    },
    { text: 'اللائحة المالية لجمعية دعم التعليم', link: url + '1zDw19LF7xMb4UvvBUvkgVypTkDBD6C-8/view?usp=drive_link' },
    { text: 'لائحة المشتريات والتعاقدات ', link: url + '12R-B3-aFKNvx96srz5b-AbyR_GbM2MER/view?usp=drive_link' },
    {
      text: 'لائحة وآلية التحقق من وصول التبرع من المتبرع إلى المستفيد النهائي',
      link: url + '12Q1fy67vKAXX4Av4HloBfX2_Oqp_3gpc/view?usp=drive_link',
    },
  ];

  const DisclosureSubPoints = [
    { text: 'إقرار التزام موظفي الجمعية بالسياسات', link: url + '15BpQXKN7jPJOEOOUiqLbq46nTje8qFda/view?usp=sharing' },
    { text: 'افصاح عدم تعارض مصالح مجلس إدارة الجمعية', link: url + '1XutHx9VRLhEHz18E_eEuqPqrNzqQ6v2p/view?usp=sharing' },
  ];

  const SOP_subPoints = [
    { text: 'الخطة الاستراتيجية', link: url + '15LtD8qqSuxBGLDDNn8-1Tn2UAtxU5_dP/view?usp=sharing' },
    { text: 'الخطة التشغيلية', link: url + '1HMYf3rANJKUbK6zbOu5v3teUSJ6D_Zu0/view?usp=sharing' },
  ];

  const annual_subPoints = [{ text: 'التقرير السنوي لعام 2023م', link: url + '1x4ffwU_jnkn4V_NpVPPMReDCQvZbgssH/view?usp=sharing' }];

  const financial_subPoints = [
    { text: 'تقرير الربع الأول 2023', link: url + '13W5HK7oHumzQYzYI5upWV875SIJ95NjO/view?usp=sharing' },
    { text: 'تقرير الربع الثاني 2023', link: url + '13BsLjqqHy0tYEc1bgcXUMCMoETzvUwgZ/view?usp=sharing' },
    { text: 'تقرير الربع الثالث 2023', link: url + '13AVpWR8meZFe2qgJuLxKUXLi-my8DMpl/view?usp=sharing' },
    { text: 'القوائم المالية لسنة 2023', link: url + '139cFfzw9CKnjjKG36FbH0AYFDSsO65a_/view?usp=sharing' },
  ];

  const meetings_subPoints = [
    { text: 'محضر اجتماع مجلس الإدارة (3) 26 فبراير 2023', link: url + '14Jho6nTqgRfWL-oN3JpmwT1JP0P3vQQA/view?usp=sharing' },
    { text: 'محضر اجتماع مجلس الادارة (4) 1 يونيو 2023', link: url + '14r6-_E-d9lE-KG3lexGx-1ksCHeqCPLU/view?usp=sharing' },
    { text: 'محضر اجتماع مجلس الإدارة (5) 5 أكتوبر 2023', link: url + '14tHKnh-0Gt5FaEDJJZTA_wlzjuEepm12/view?usp=sharing' },
    { text: 'محضر اجتماع مجلس الادارة (6) 30 أكتوبر 2023', link: url + '14tL0qtwaT3c91H7aiyDUfw4D1NJZ6BRg/view?usp=sharing' },
  ];

  const generalAssembly_subPoints = [
    { text: ' اجتماع الجمعية العمومية العادية 25 فبراير 2024', link: url + '1WEI4kDBHBoXe4_IF-_yifjSi11xCSQDJ/view?usp=sharing' },
    { text: 'اجتماع الجمعية العمومية الغير عادية 25 فبراير 2024', link: url + '1W9IXVozrZu6inc3wEac59db6AW9mxOId/view?usp=sharing' },
  ];

  return (
    <div className='about-container'>
      {homePageData && membersData && (
        <>
          <div className='hero-section'>
            <div className={aboutContainerStyle}>
              <div className='side-nav'>
                <a
                  href='#main'
                  className='side-nav-item'
                  onClick={() => {
                    handelTab(1);
                    setActiveNav(false);
                  }}
                >
                  {setText('main_titlte')}
                </a>
                <a
                  href='#governance'
                  className='side-nav-item'
                  onClick={() => {
                    handelTab(5);
                    setActiveNav(false);
                  }}
                >
                  {'الحوكمة'}
                </a>
                <a
                  href='#about'
                  className='side-nav-item'
                  onClick={() => {
                    handelTab(2);
                    setActiveNav(false);
                  }}
                >
                  {setText('about_title')}
                </a>
                <a
                  href='#members'
                  className='side-nav-item'
                  onClick={() => {
                    handelTab(3);
                    setActiveNav(false);
                  }}
                >
                  {'الأعضاء'}
                </a>
                <a href='https://store.taalum.org.sa' className='side-nav-item' onClick={() => setActiveNav(false)}>
                  {'المتجر الالكتروني'}
                </a>
                <a
                  href='#contact'
                  className='side-nav-item'
                  onClick={() => {
                    handelTab(4);
                    setActiveNav(false);
                  }}
                >
                  {'تواصل معنا'}
                </a>
              </div>
            </div>

            <Header
              handelTab={handelTab}
              homeLinkText={setText('main_titlte')}
              aboutLinkText={setText('about_title')}
              setActiveNav={setActiveNav}
              activeNav={activeNav}
              user={user}
            />
            <div className='intro'>
              <p className='intro-title'>{setText('main_section_1')}</p>
              <div className='sub-title'>
                <>{setText('main_section_1_1')}</>
              </div>
              <div className='btn-container'>
                {!user?.email ? (
                  <div>
                    <Link to='/applicant-login' className='login-btn me-2'>
                      المستفيدين
                    </Link>

                    <Link to='/provider-login' className='login-btn'>
                      مزودين الخدمات
                    </Link>
                  </div>
                ) : (
                  <Link
                    to={
                      user.account_type === 1
                        ? `/application-request`
                        : user.account_type === 2
                        ? `/applications`
                        : user.account_type === 3
                        ? `/home`
                        : user.account_type === 4 && `/home`
                    }
                    className='login-btn loggedin'
                  >
                    {user.account_type === 1
                      ? 'لوحة التحكم '
                      : user.account_type === 2
                      ? 'لوحة التحكم '
                      : user.account_type === 3
                      ? 'طلباتي'
                      : user.account_type === 4 && 'طلباتي'}

                    <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='arrow bi bi-arrow-left' viewBox='0 0 16 16'>
                      <path
                        fillRule='evenodd'
                        d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
                      />
                    </svg>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {showTab === 1 ? (
            <>
              <div className='about-section'>
                <h2 className='title'>{setText('main_section_2')}</h2>
                <p className='about-p'>{setText('main_section_2_1')}</p>
              </div>
              <div id='main' className='goals-section'>
                <h2 className='title goals-title'>{setText('main_section_3')}</h2>
                {/* <div className='flex-row goals-div'> */}
                <div className='container-div'>
                  <div className='col-goals'>
                    <div className='goals-container '>
                      <div className='goals-img'>
                        <img className='goals-icon' src={findImage('main_section_3_1_1')} alt='' />
                      </div>
                      <h4 className='goals-subtitle'>{setText('main_section_3_1_2')}</h4>
                      <p className='goals-p'>{setText('main_section_3_1_3')}</p>
                    </div>
                    <hr className='goals-line'></hr>
                    <div className='goals-container'>
                      <div className='goals-img'>
                        <img className='goals-icon' src={findImage('main_section_3_2_1')} alt='' />
                      </div>
                      <h4 className='goals-subtitle'>{setText('main_section_3_2_2')}</h4>
                      <p className='goals-p'>{setText('main_section_3_2_3')}</p>
                    </div>
                    <hr className='goals-line'></hr>
                    <div className='goals-container'>
                      <div className='goals-img'>
                        <img className='goals-icon' src={findImage('main_section_3_3_1')} alt='' />
                      </div>
                      <h4 className='goals-subtitle'>{setText('main_section_3_3_2')}</h4>
                      <p className='goals-p'>{setText('main_section_3_3_3')}</p>
                    </div>
                    <hr className='goals-line'></hr>
                    <div className='goals-container'>
                      <div className='goals-img'>
                        <img className='goals-icon goals-idea-icon' src={findImage('main_section_3_4_1')} alt='' />
                      </div>
                      <h4 className='goals-subtitle'>{setText('main_section_3_4_2')}</h4>
                      <p className='goals-p'>{setText('main_section_3_4_3')}</p>
                    </div>{' '}
                  </div>
                </div>
              </div>
              <div id='features' className='feature-section'>
                <div className='flex-footer flex-small'>
                  <div className='features-list'>
                    <h2 className='title feature-title'>{setText('main_section_4')}</h2>
                    <p className='feature-subtitle'>{setText('main_section_4_1')}</p>
                    {featuresArrClean?.map((feature, index) => (
                      <p className='feature-item' key={index}>
                        {feature.trim()}
                      </p>
                    ))}
                  </div>
                  <div className='feature-icon'>
                    <img className='feature-img' src={Idea} alt='' />
                  </div>
                </div>
              </div>
              <div id='services' className='services-section'>
                <h2 className='title services-title mt-4'>{setText('main_section_5')}</h2>
                <p className=' services-p'>{setText('main_section_5_1')}</p>
                <div className='flex-services pt-6 pb-8 services-div'>
                  <div className='services-item'>
                    <img className='services-img' src={findImage('main_section_5_2_1')} alt='' />
                    <h4 className='services-subtitle'>{setText('main_section_5_2_2')}</h4>
                  </div>
                  <div className='services-item services-item'>
                    <img className='services-img services-img' src={findImage('main_section_5_3_1')} alt='' />
                    <h4 className='services-subtitle services-subtitle'>{setText('main_section_5_3_2')}</h4>
                  </div>
                  <div className='services-item services-item'>
                    <img className='services-img services-img' src={findImage('main_section_5_4_1')} alt='' />
                    <h4 className='services-subtitle services-subtitle'>{setText('main_section_5_4_2')}</h4>
                  </div>
                  <div className='services-item services-item'>
                    <img className='services-img services-img' src={findImage('main_section_5_5_1')} alt='' />
                    <h4 className='services-subtitle services-subtitle'>{setText('main_section_5_5_2')}</h4>
                  </div>
                  <div className='services-item services-item'>
                    <img className='services-img services-img' src={findImage('main_section_5_6_1')} alt='' />
                    <h4 className='services-subtitle services-subtitle'>{setText('main_section_5_6_2')}</h4>
                  </div>
                </div>
                <div id='donate' className='services-section'>
                  <h2 className='title donate-title'>{setText('main_section_6')}</h2>
                  <p style={{ maxWidth: '1072px' }} className='services-p mb-76'>
                    {setText('main_section_6_1')}
                  </p>
                  <div className='flex-donate'>
                    <div className='services-donate'>
                      <img className='services-img' src={associationStore} alt='' />
                      <div className='donation-link align-items-center'>
                        <a href={hrefLink} target='_blank' rel='noreferrer' className='donation-span'>
                          {' '}
                          {setText('main_section_6_2')}
                        </a>
                        <img src={associationStoreIcon} alt='' className='donate-icons' />
                      </div>
                    </div>
                    <div className='services-donate'>
                      <img className='services-img' src={directDonation} alt='' />
                      <div className='donation-link align-items-center'>
                        <span onClick={toggleModal} className='donation-span'>
                          {' '}
                          التبرع المباشر
                        </span>
                        <img src={directDonationIcon} alt='' className='donate-icons' />
                      </div>
                    </div>
                  </div>{' '}
                </div>
              </div>
            </>
          ) : null}
          {showTab === 2 ? (
            <>
              <div id='about' className='about-section'>
                <h2 className='title'>{setText('about_section_1')}</h2>
                <p className='about-p'>{setText('about_section_1_1')}</p>
              </div>

              <div className='feature-section'>
                <div className='flex-row  flex-small custom-flex'>
                  <div className='features-list'>
                    <h2 className='title feature-title'>{setText('about_section_2')}</h2>
                    <p className='feature-subtitle' style={{ fontWeight: 'normal' }}>
                      {setText('about_section_2_1')}
                    </p>
                    <h2 className='title feature-title'>{setText('about_section_3')}</h2>
                    <p className='feature-subtitle' style={{ fontWeight: 'normal', marginLeft: '3%' }}>
                      {setText('about_section_3_1')}
                    </p>
                  </div>
                  <div className='feature-icon'>
                    <img className='feature-img' src={Idea} alt='' />
                  </div>
                </div>
              </div>
              {membersData &&
                membersData.map((card, i) => (
                  <div key={`card-${i}`}>
                    <div className='services-section' style={{ paddingTop: 'var(--customPaddingTop2)' }}>
                      {<h2 className='title services-title'>{card.title}</h2>}
                    </div>
                    <div className='container-names'>
                      {card.members.map((member) => (
                        <div className='item-names' key={member.id}>
                          <span>{member.name}</span>
                          <div className='font-regular'>
                            {MEMBERS_POSITIONS.find((pos) => pos.value === member.position)?.ar_label +
                              ' ' +
                              card.title.split(' ').slice(1).join(' ')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              <div className='services-section' style={{ paddingTop: 'var(--customPaddingTop)' }}>
                <h2 className='title services-title'>{setText('about_section_10')}</h2>
              </div>
              <div className='container-box'>
                <div className='top-content'>
                  <h2>{setText('about_section_10_1')}</h2>
                </div>
                <div className='content-flex'>
                  <div className='content-flex-column'>
                    <div className='sub-content'>{setText('about_section_10_2')}</div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_2_1')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_2_2')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_2_3')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_2_4')}
                    </div>
                  </div>
                  <div className='content-flex-column'>
                    <div className='sub-content'>{setText('about_section_10_3')}</div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_3_1')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_3_2')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_3_3')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_3_4')}
                    </div>
                  </div>
                  <div className='content-flex-column'>
                    <div className='sub-content'>{setText('about_section_10_4')}</div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_4_1')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_4_2')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_4_3')}
                    </div>
                    <div className='item-names' style={{ fontFamily: 'DIN Regular' }}>
                      {setText('about_section_10_4_4')}
                    </div>
                  </div>
                </div>
              </div>

              <div className='container-box-2'>
                <div className='title-box'>{setText('about_section_11')}</div>
                <div className='flex-box-2'>
                  <div className='txt-box-1'>{setText('about_section_11_1')}</div>
                  <div className='txt-box-1'>{setText('about_section_11_2')}</div>
                </div>
              </div>

              <div className='container-box-2' style={{ marginBottom: '120px' }}>
                <div className='title-box'>{setText('about_section_12')}</div>
                <div className='flex-box-3'>
                  <div className='txt-box-1'>{setText('about_section_12_1')}</div>
                  <div className='txt-box-1'>{setText('about_section_12_2')}</div>
                  <div className='txt-box-1'>{setText('about_section_12_3')}</div>
                  <div className='txt-box-1'>{setText('about_section_12_4')}</div>
                </div>
              </div>
            </>
          ) : null}
          {showTab === 3 ? (
            <div id='members' style={{ paddingBottom: '100px' }}>
              {membersData &&
                membersData.map((card, i) => (
                  <div key={`card-${i}`}>
                    <div className='services-section' style={{ paddingTop: 'var(--customPaddingTop2)' }}>
                      {<h2 className='title services-title'>{card.title}</h2>}
                    </div>
                    <div className='container-names'>
                      {card.members.map((member) => (
                        <div className='item-names' key={member.id}>
                          <span>{member.name}</span>
                          <div className='font-regular'>
                            {MEMBERS_POSITIONS.find((pos) => pos.value === member.position)?.ar_label +
                              ' ' +
                              card.title.split(' ').slice(1).join(' ')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          ) : null}
          {showTab === 4 ? (
            <div id='contact' className='about-section' style={{ maxWidth: '100%', marginBottom: '30px' }}>
              <h2 className='title'>تواصل معنا</h2>
              <div className='contact-container'>
                <div className='contact-item'>
                  <img src={Call} alt='' className='mobileLogo' />
                  <a href='tel:0116222012'>0116222012</a>
                </div>
                <div className='contact-item'>
                  <img src={WhatsappLogo} alt='' style={{ width: '85px' }} />
                  <a href='https://wa.me/0116222012'>0116222012</a>
                </div>
                <div className='contact-item'>
                  <img src={Email} alt='' className='emailLogo' style={{ filter: 'none' }} />
                  <a href='mailto:PR@taalum.org.sa'>PR@taalum.org.sa</a>
                </div>
                <div className='contact-item'>
                  <img src={xLogo} alt='' width={20} />
                  <a className='text-white' href='https://twitter.com/taalum_org' style={{ color: 'white', direction: 'ltr' }}>
                    @taalum_org
                  </a>
                </div>
                <div className='contact-item'>
                  <img src={userPic} alt='' width={20} />
                  <a className='text-white' href='https://twitter.com/taalum_org' style={{ color: 'white', direction: 'ltr' }}>
                    المدير التنفيذي: د. عادل العمري
                  </a>
                  <a
                    className='text-white'
                    href='mailto:a.alomari@taalum.org.sa'
                    style={{ color: 'white', direction: 'ltr', marginTop: 8 }}
                  >
                    a.alomari@taalum.org.sa
                  </a>
                </div>
                <div className='contact-item'>
                  <img src={Map} alt='' className='locationLogo' style={{ filter: 'none' }} />
                  <span className=''>الرياض 13321, 6531, 3059 ,جمعية دعم التعليم</span>
                </div>
              </div>
            </div>
          ) : null}
          {showTab === 5 ? (
            //TODO: Create a component for this section

            <div id='governance' className='about-section' style={{ maxWidth: '100%', marginBottom: '30px' }}>
              <h1 className='gov-title'>الحوكمة</h1>
              <div id='governance'>
                <ContentComponent mainPoint='لوائح وسياسات الجمعية' subPoints={ars_subPoints} />
                {/*ARS = Association's Regulations and Policies */}
                <ContentComponent mainPoint='الإفصاح' subPoints={DisclosureSubPoints} />
                <ContentComponent mainPoint='الإجراءات التشغيلية' subPoints={SOP_subPoints} />
                <ContentComponent mainPoint='التقارير السنوية' subPoints={annual_subPoints} />
                <ContentComponent mainPoint='التقارير المالية' subPoints={financial_subPoints} />
                <ContentComponent mainPoint='الميزانية والموازنات' />
                <ContentComponent mainPoint='محاضر الاجتماع' subPointDiv='محاضر مجلس الإدارة لعام 2023م' subPoints={meetings_subPoints} />
                <ContentComponent subPointDiv='محاضر الجمعية العمومية' subPoints={generalAssembly_subPoints} />
              </div>
            </div>
          ) : null}
          {modal && (
            <div className='modal overflow-auto'>
              <div onClick={toggleModal} className='overlay'></div>
              <div className='modal-content-payment'>
                <div className='flex-modal'>
                  <div className='div-modal'>
                    <p className='header-popup-payment'>حسابات الجمعية</p>
                    <p className='sub-title-popup-payment '>يمكنكم تحويل مبلغ التبرع من أي بنك إلى حسابات الجمعية في البنوك التالية</p>
                  </div>
                  <div className='box-icon-close'>
                    <div className='box2-icon-close'>
                      <button onClick={toggleModal} className='icone-close-popup-payment'>
                        <img src={iconeClosePopup} alt='' width='35%' />
                      </button>
                    </div>
                  </div>
                </div>

                <div className='box-payment-accounts'>
                  {banksData &&
                    banksData.map((bank) => (
                      <div className='payment-accounts'>
                        <img src={bank.bank_icon} alt='' className='payment-icons' />
                        <div>
                          <div className='span-payment'>{bank.name}</div>
                          <span className='span-payment'>{bank.iban_account}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div></div>
            </div>
          )}
        </>
      )}
      <Footer mt={'mt-4'} />
    </div>
  );
};

export default LandingPage;
